.App {
  text-align: center;
  background: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.single-table {
  margin-bottom: 0 !important;
}

.single-table > * > *> th {
  padding: 0.75rem !important;
}

.single-table > * > *> td {
  vertical-align: middle;
}

td.td-header {
  text-align: right;
}
tr.predefinedResults > td {
  vertical-align: top;
}
tr.predefinedResults > td.td-header {
  vertical-align: top;
  text-align: right;
  padding: 0.75rem !important;
}

.centered-input {
  text-align: center;
}

.align-run-segment-table {
  align-self: center!important;
  display: inline-table;
}

input.extra-disabled:disabled {
  background-color: #212529!important;
  opacity: 1;
  color: #ffffff;
}

input.edit {
  background-color: #ffbb89 !important;
  color: #212529;
}

button.btn-right-append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-header {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-header > img {
  height: 100px;
}
.app-header > * {
  margin: 2rem 0.75rem;
}

button.predefinedResultsButton {
  background: none;
}
div.predefinedResultsContainer {
  padding: 0.75rem 0;
  display: grid;
}